<template>
  <StoryblokComponent v-if="story" :blok="story.content" />
</template>

<script setup>
defineI18nRoute(false)
const route = useRoute()
const { setLocale } = useI18n()
setLocale(route.params.lang)
const story = await useAsyncStoryblok(`parken/${route.params.lang}/${route.params.slug}`, { version: 'draft' })
</script>
